import React from 'react';
import * as Icon from 'react-feather';

//import OwlCarousel from 'react-owl-carousel';

// Client Images
import Client1 from '../../assets/images/client-image/pic.png'
import Client2 from '../../assets/images/client-image/pic.png'
import Client3 from '../../assets/images/client-image/pic.png'

// Shape Images
import Shape1 from "../../assets/images/shape1.png";
import Shape2 from "../../assets/images/shape2.svg";
import Shape3 from "../../assets/images/shape3.svg";
import Shape4 from "../../assets/images/shape4.svg";

import Loadable from '@loadable/component'
//const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: false,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 5000,
    items: 1,
    animateOut: 'fadeOut',
}

const FeedbackStyleFour = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="ml-feedback-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Our Clients Feedback</h2>
                    <div className="bar"></div>
                    <p>Our satisfied customers speak for themselves. Read what they have to say about our top-notch service and customized solutions.</p>
                </div>

                {/*display ? <OwlCarousel 
                    className="ml-feedback-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={Client1} alt="image" />
                            <h3>SolidPro ES</h3>
                            <span>India</span>
                        </div>
                        <p>Your automation services have exceeded our expectations. The increased efficiency and productivity has made a noticeable impact on our business operations. Thank you for delivering such exceptional service.</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={Client2} alt="image" />
                            <h3>Laksh S</h3>
                            <span>Consultant <br />United States</span>
                        </div>
                        <p>Our Salesforce hassle was resolved efficiently and effectively through the outstanding automation service provided by your team. Your professionalism and expertise were truly impressive, and we appreciate the time and resources saved.</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>

                    <div className="single-ml-feedback-item">
                        <div className="client-info">
                            <img src={Client3} alt="image" />
                            <h3>Ashok</h3>
                            <span>CA <br /> India</span>
                        </div>
                        <p>Thanks to your exceptional & cost effective automation service which streamlined our regular filing of GST invoices with Zoho CRM integration. Your team's attention to detail and professionalism were remarkable, and we highly recommend your services.</p>

                        <div className="rating">
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                            <Icon.Star />
                        </div>
                    </div>
    </OwlCarousel> : ''*/}
            </div>

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape3">
                <img src={Shape3} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </div>
    );
}

export default FeedbackStyleFour;